export default [
  {
    nameFirst: 'Jacob C.',
    nameLast: 'Kimmel',
    email: 'jacob@calicolabs.com',
    affiliations: [{ organization: 'Calico' }],
    corresponding: true,
  },
  {
    nameFirst: 'Nelda',
    nameLast: 'Yi',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,    
  },
  {
    nameFirst: 'Margaret',
    nameLast: 'Roy',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,    
  },  
  {
    nameFirst: 'David G.',
    nameLast: 'Hendrickson',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,
  },
  {
    nameFirst: 'David R.',
    nameLast: 'Kelley',
    email: 'drk@calicolabs.com',
    affiliations: [{ organization: 'Calico' }],
    corresponding: true,
  },
];
