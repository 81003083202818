import React from 'react';
import { Bullets } from '@calico/calico-ui-editorial';
import { LinkExternal } from '@calico/calico-ui-kit';

const BASE_URL = 'https://storage.googleapis.com/calico-website-myo-storage';
const META_DES = "Inferred cell types ('cell_type'), ages ('age'), and animal of origin ('animal') are available as row annotations. Note that 'animal' is not a unique identifier. To identify unique animals, use the combination of 'age' and 'animal'."

export default [
  {
    format: 'h5ad',
    name: 'Murine muscle mononuclear cell count data',
    size: '841 MB',
    src: `${BASE_URL}/adata_log1p_cpm_annotated.h5ad`,
    title: 'Murine muscle mononuclear cell count data',
    content: (
      <div className="max-line-length">
        <p>Data are formatted following the AnnData conventions.
        Genes expressed in few cells or replicates have been removed from this object, representing the matrix we use for downstream analysis.
        We have removed lincRNAs without a splice site and pseudogenes prior to normalization.
        </p>
        <p>{META_DES}</p>
        <p>Includes the following files:</p>
        <Bullets
          bullets={[
            {
              title: <strong>adata_log1p_cpm_annotated.h5ad</strong>,
              content: 'Single cell RNA count data for murine muscle mononuclear cells.',
            }
          ]}
          className="grey"
        />
      </div>
    ),
  },
];
